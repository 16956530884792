export const Banner = ({
  t,
  img,
  title,
  description,
  href,
  sideImg,
  noButton,
  contentTopMargin,
}) => {
  return (
    <div>
      {/* Background image. */}
      <div
        className="w-full h-[85vh] sm:h-[60vh] lg:h-[70vh] bg-green-900 
      overflow-hidden"
      >
        <img src={img} className="opacity-30 h-full w-full" />
      </div>
      {/* Content */}
      <div
        className="absolute top-0 z-20 mt-[50px] w-full px-8 sm:px-16 sm:pt-8 flex
      justify-start items-center"
      >
        <div
          className={`w-fit ${
            contentTopMargin ? contentTopMargin : "mt-16"
          } sm:mt-6 flex sm:justify-around space-x-16`}
        >
          <div className="w-[80vw] sm:w-[50vw] lg:w-[35vw]">
            <p className="text-green-500 text-bold text-4xl font-bold">
              {title}
            </p>
            <p className="text-base xl:text-2xl text-slate-100 mt-2">
              {description}
            </p>
            {!noButton && (
              <div className="mt-4">
                <a
                  target="_blank"
                  href={href}
                  className="bg-green-500 px-2 py-1 rounded text-xl
            text-slate-50 duration-300 hover:bg-green-700 cursor-pointer"
                >
                  {t("buttonAction")}
                </a>
              </div>
            )}
          </div>
        </div>
        <img
          src={sideImg}
          className="hidden lg:block w-[60vw] h-[50vh] 2xl:w-[50vw] 
          rounded-xl ml-16"
        />
      </div>
    </div>
  );
};
