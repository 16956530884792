export const AboutUs = ({ t }) => {
  return (
    <div
      id="about-us"
      className="bg-slate-100 py-8 text-center px-8
    lg:px-0"
    >
      <p
        className="text-base sm:text-xl lg:text-2xl text-slate-700 sm:mt-8 mt-12 lg:mt-4 
      w-full mx-auto text-left lg:px-36"
      >
        {t("aboutus.text")}
      </p>
    </div>
  );
};
