const { twMerge } = require("tailwind-merge");

export const HeaderLink = ({ href, children, className }) => {
  const clss = twMerge(
    "text-slate-50 text-base duration-300 cursor-pointer \
    hover:text-green-400",
    className
  );
  return (
    <a href={href} className={clss}>
      {children}
    </a>
  );
};
