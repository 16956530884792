import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "pt-BR",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      "pt-BR": {
        translation: {
          buttonAction: "Conheça já!",
          header: {
            link1: "SOBRE",
            link2: "SOLUÇÕES",
            link3: "CONTATO",
          },
          pixviewer: {
            description:
              "O PixViewer é uma plataforma online, concebida com a finalidade \
              de unificar e padronizar informações extraídas de imagens aéreas. \
              A iniciativa surge como resposta à complexidade associada à \
              padronização de dados, tanto no que diz respeito à visualização \
              quanto à navegação no campo. Inicialmente, focalizamos a \
              integração de dados provenientes do InfoRow; contudo, \
              vislumbramos futuras incorporações de outras fontes de dados.",
            description2:
              "O PixViewer não apenas proporciona ao usuário acesso aos \
              relatórios de forma mais dinâmica e ágil, seja em ambientes \
              desktop ou mobile, como também facilita o compartilhamento \
              eficiente desses dados entre pessoas e equipes. Essa \
              funcionalidade promove uma colaboração fluida, permitindo que \
              as informações sejam acessadas e utilizadas de maneira rápida \
              e segura por todos os membros envolvidos. ",
          },
          irmanager: {
            description:
              "A Pix2Agro é parceira na distribuição e desenvolvimento do \
              software InfoRow, sendo este um software de extração de \
              informações a partir de imagens aéreas. Ele é empregado no \
              processamento de mais de 1 milhão de hectares de áreas de \
              cana-de-açúcar anualmente, o InfoRow utiliza tecnologia de \
              ponta para entregar ferramentas essenciais para o mercado \
              agro, como identificação geográfica de plantas, reconstrução \
              de fileiras de plantio (utilizável em piloto automático), \
              separação dos trechos com ausência/falha de vegetação e \
              verificação da qualidade de paralelismo das fileiras.",
            step1: "Identificação de plantas",
            step2: "Reconstrução de fileiras",
            step3: "Extração de falhas",
            step4: "Relatórios automatizados",
          },
          aboutus: {
            title: "Sobre nós",
            text: "Empresa focada no desenvolvimento, treinamento e suporte técnico para ferramentas dedicadas ao processamento de imagens áreas obtidas por Aeronaves Remotamente Pilotadas.Atualmente a empresa é a representante oficial do software InfoRow que já utilizado atualmente em mais de 600 mil hectares de áreas produtivas por ano.",
            info1: "usinas atendidas",
            info2: "prestadores de serviço",
            info3: "grandes plataformas online",
            info4: "usinas na américa central",
          },
          contact: {
            title: "Entre em contato",
          },
        },
      },
      en: {
        translation: {
          buttonAction: "Discover now!",
          header: {
            link1: "ABOUT",
            link2: "SOLUTIONS",
            link3: "CONTACT",
          },
          pixviewer: {
            description:
              "PixViewer represents an advanced application, designed for the integrated import and storage of all reports from IRPlus, including incidents of failures, trampling, line analysis, and performance records. This platform facilitates dynamic visualization and promotes the efficient sharing of information among users. Additionally, it offers functionalities for the effective management of users and corporate resources, as well as the import of projects for mobile application, allowing field navigation with GPS assistance, among other relevant features.",
            feature1: "Visualize your areas on any device",
            feature2: "Navigate your projects with GPS",
            feature3: "Share your folders and projects with other users",
          },
          irmanager: {
            description:
              "Currently used in over 600 thousand hectares of productive areas, InfoRow utilizes cutting-edge technology to deliver essential tools for the agricultural market, such as geographical identification of plants, reconstruction of planting rows (usable in autopilot), separation of sections with absence/failure of vegetation, and verification of the parallelism quality of the rows, in addition to new tools and solutions being developed all the time.",
            feature1: "Plant identification",
            feature2: "Reconstruction of rows",
            feature3:
              "Extraction of reports on failures, trampling, and performance.",
          },
          aboutus: {
            title: "About Pix2Agro",
            text: "Company focused on development, training, and technical support for tools dedicated to the processing of aerial images obtained by Remotely Piloted Aircraft. Currently, the company is the official representative of the InfoRow software, which is already used in more than 600,000 hectares of productive areas per year.",
          },
          contact: {
            title: "Get in touch",
          },
        },
      },
      es: {
        translation: {
          buttonAction: "¡Descubre ahora!",
          header: {
            link1: "SOBRE",
            link2: "SOLUCIONES",
            link3: "CONTACTO",
          },
          pixviewer: {
            description:
              "PixViewer representa una aplicación avanzada, diseñada para la importación y almacenamiento integrado de todos los informes de IRPlus, incluyendo incidentes de fallos, pisoteo, análisis de líneas y registros de actuación. Esta plataforma facilita una visualización dinámica y promueve el intercambio eficiente de información entre los usuarios. Adicionalmente, ofrece funcionalidades para la gestión eficaz de usuarios y recursos corporativos, así como la importación de proyectos para la aplicación móvil, permitiendo la navegación en campo con la ayuda de GPS, entre otras características relevantes.",
            feature1: "Visualiza tus áreas en cualquier dispositivo",
            feature2: "Navega en tus proyectos con GPS",
            feature3: "Comparte tus carpetas y proyectos con otros usuarios",
          },
          irmanager: {
            description:
              "Actualmente utilizado en más de 600 mil hectáreas de áreas productivas, InfoRow utiliza tecnología de punta para entregar herramientas esenciales para el mercado agrícola, como la identificación geográfica de plantas, reconstrucción de filas de plantío (utilizable en piloto automático), separación de tramos con ausencia/falla de vegetación y verificación de la calidad de paralelismo de las filas, además de herramientas y soluciones nuevas siendo desarrolladas todo el tiempo.",
            feature1: "Identificación de plantas",
            feature2: "Reconstrucción de filas",
            feature3: "Extracción de informes de fallos, pisoteo y actuación.",
          },
          aboutus: {
            title: "Acerca de Pix2Agro",
            text: "Empresa enfocada en el desarrollo, capacitación y soporte técnico para herramientas dedicadas al procesamiento de imágenes aéreas obtenidas por Aeronaves Remotamente Pilotadas. Actualmente, la empresa es la representante oficial del software InfoRow, que ya se utiliza actualmente en más de 600.000 hectáreas de áreas productivas por año.",
          },
          contact: {
            title: "Ponte en contacto",
          },
        },
      },
    },
  });

export default i18n;
