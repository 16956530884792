import "./App.css";
import gapsImg from "./images/pixviewer_gaps.png";
import irplusImg from "./images/irmanager_print.png";
import { Header } from "./components/Header";
import { Banner } from "./components/Banner";
import pixviewerImg from "./images/pixviewer_gaps_overview.png";
import { Card } from "./components/Card";
import irmanagerImg from "./images/irmanager_overview.png";
import { PlantsIllustration } from "./icons/PlantsIllustration";
import { FarmIllustration } from "./icons/FarmIllustration";
import { ReportIllustration } from "./icons/RerportIllustration";
import { CardsList } from "./components/CardsList";
import { AboutUs } from "./components/AboutUs";
import { Contact } from "./components/Contact";
import { useTranslation } from "react-i18next";
import { PixViewerMobileAnimation } from "./components/PixViewerMobileAnimation";
import { OurClients } from "./components/OurClients";
import { IRManagerSteps } from "./components/IRManagerSteps";

function App() {
  const { t } = useTranslation();

  return (
    <>
      <Header t={t} />
      {/* PIXVIEWER */}
      <div className="relative">
        <Banner
          t={t}
          img={pixviewerImg}
          title="PixViewer"
          description={t("pixviewer.description")}
          sideImg={gapsImg}
          noButton
        />
        <PixViewerMobileAnimation t={t} />
      </div>
      {/* IRMANAGER */}
      <div className="relative">
        <Banner
          t={t}
          img={irmanagerImg}
          title="InfoRow"
          description={t("irmanager.description")}
          href="https://irmanager.com.br"
          sideImg={irplusImg}
          contentTopMargin={8}
        />
        {/* TODO: Adicionar tradução em IRManagerSteps. */}
        <IRManagerSteps t={t} />
        {/* TODO: Adicionar tradução em OurCLients. */}
        <OurClients t={t} />
        <AboutUs t={t} />
        <Contact t={t} />
        {/* RODAPÉ */}
        <div
          className="flex w-full h-[40px] bg-slate-50 justify-center 
        items-center"
        >
          <p className="text-base font-bold text-slate-400">Pix2Agro</p>
        </div>
      </div>
    </>
  );
}

export default App;
