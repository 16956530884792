import { useEffect, useState } from "react";
import { userInMobile } from "../helpers/layout";

export const PixViewerMobileAnimation = ({ t }) => {
  const [isMobile, setIsMobile] = useState(false);

  const splineDesktopUrl =
    "https://my.spline.design/untitled-18982af8367e032e3955faf4090a662a/";
  const splineMobileUrl =
    "https://my.spline.design/pix2agroappmobileversion-026a187853d72361edf76ac0ae62e36c/";

  const onButtonClick = (href) => {
    window.open(href, "_blank");
  };

  useEffect(() => {
    setIsMobile(userInMobile());
  }, [window]);

  return (
    <div className="flex items-center bg-slate-50 py-0">
      <div className="w-full sm:w-[50vw] lg:w-[40vw] p-8 sm:p-0 sm:pl-16">
        <p className="text-xl xl:text-2xl text-slate-700">
          {t("pixviewer.description2")}
        </p>
        <button
          onClick={() => onButtonClick("https://pixviewer.cloud")}
          className="bg-green-500 px-2 py-1 rounded text-xl mt-8
            w-full text-slate-50 duration-300 hover:bg-green-700"
        >
          {t("buttonAction")}
        </button>
      </div>
      <div className="hidden sm:block">
        <iframe
          src={splineMobileUrl}
          className="w-[50vw] overflow-hidden pointer-events-none h-[750px]
          max-mobile:h-[500px] select-none block lg:hidden"
        />
        <iframe
          src={splineDesktopUrl}
          className="w-[60vw] overflow-hidden pointer-events-none h-[750px]
          max-mobile:h-[500px] select-none hidden lg:block"
        />
      </div>
    </div>
  );
};
