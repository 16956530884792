export const OurClients = () => {
  return (
    <div className="bg-slate-100">
      <div className="pt-8 text-center">
        <h2 className="text-4xl text-green-500 font-bold">Sobre nós</h2>
      </div>
      <div className="h-[12vw] px-4 pb-8 grid grid-cols-4 mt-4 sm:mt-12 lg:mt-0">
        <div className="flex items-center mx-auto">
          <div className="text-center">
            <p className="text-4xl sm:text-6xl text-green-500 font-bold">30</p>
            <p className="text-sm sm:text-base lg:text-2xl text-slate-700 sm:mt-4">
              usinas atendidas
            </p>
          </div>
        </div>
        <div className="flex items-center mx-auto">
          <div className="text-center">
            <p className="text-4xl sm:text-6xl text-green-500 font-bold">21</p>
            <p className="text-sm sm:text-base lg:text-2xl text-slate-700 sm:mt-4">
              prestadores de serviço
            </p>
          </div>
        </div>
        <div className="flex items-center mx-auto">
          <div className="text-center">
            <p className="text-4xl sm:text-6xl text-green-500 font-bold">3</p>
            <p className="text-sm sm:text-base lg:text-2xl text-slate-700 sm:mt-4">
              grandes plataformas online
            </p>
          </div>
        </div>
        <div className="flex items-center mx-auto">
          <div className="text-center">
            <p className="text-4xl sm:text-6xl text-green-500 font-bold">6</p>
            <p className="text-sm sm:text-base lg:text-2xl text-slate-700 sm:mt-4">
              usinas na américa central
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
