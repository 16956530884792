import { ContactIllustration } from "../icons/ContactIllustration";
import { EmailIcon } from "../icons/EmailIcon";
import { InstagramIcon } from "../icons/InstagramIcon";
import { LinkedInIcon } from "../icons/LinkedInIcon";
import { WhatsAppIcon } from "../icons/WhatsAppIcon";
import { YoutubeIcon } from "../icons/YoutubeIcon";

export const Contact = ({ t }) => {
  return (
    <div id="contact" className="bg-slate-50 py-4 sm:py-16 text-center">
      <div className="flex justify-center">
        <ContactIllustration
          height={300}
          width={400}
          className="hidden sm:block"
        />
        <div className="text-left">
          <h2 className="text-green-500 text-2xl sm:text-4xl font-bold">
            {t("contact.title")}
          </h2>
          <a
            className="flex items-center mt-4"
            href="mailto:contato@pix2agro.com.br"
          >
            <EmailIcon height={24} width={24} className="fill-slate-700" />
            <p className="text-slate-700 text-xl ml-2">
              comercial@pix2agro.com
            </p>
          </a>
          <div className="mt-8 flex items-center">
            <a
              className="group"
              href="https://api.whatsapp.com/send?phone=5519996542014&text=Ol%C3%A1.%20Gostaria%20de%20conhecer%20mais%20os%20softwares%20que%20voc%C3%AAs%20disponibilizam."
              target="_blank"
            >
              <WhatsAppIcon
                height={48}
                width={48}
                className="fill-slate-700 duration-300 group-hover:fill-green-500"
              />
            </a>
            <a
              className="ml-4 group"
              href="https://www.linkedin.com/company/pix2agro/about/"
              target="_blank"
            >
              <LinkedInIcon
                height={48}
                width={48}
                className="fill-slate-700 duration-300 group-hover:fill-green-500"
              />
            </a>
            <a
              className="ml-4 group"
              href="https://www.instagram.com/pix2agro/"
              target="_blank"
            >
              <InstagramIcon
                height={48}
                width={48}
                className="fill-slate-700 duration-300 group-hover:fill-green-500"
              />
            </a>
            <a
              className="ml-4 group"
              href="https://www.youtube.com/@pix2agro902/videos"
              target="_blank"
            >
              <YoutubeIcon
                height={48}
                width={48}
                className="fill-slate-700 duration-300 group-hover:fill-green-500"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
