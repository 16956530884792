import ptsImg from "../images/pontos.png";
import rowsImg from "../images/linhas.png";
import gapsImg from "../images/falhas.png";
import reportsImg from "../images/relatorio.png";

export const IRManagerSteps = ({ t }) => {
  return (
    <>
      {/* DESKTOP */}
      <div
        className="bg-slate-50 p-16 hidden lg:grid grid-cols-4 
      gap-x-16"
      >
        <div className="text-center h-[15]">
          <img src={ptsImg} className="rounded h-full w-full" />
          <p className="text-slate-700 text-xl">{t("irmanager.step1")}</p>
        </div>
        <div className="text-center h-[15]">
          <img src={rowsImg} className="rounded h-full w-full w-min-[100%]" />
          <p className="text-slate-700 text-xl">{t("irmanager.step2")}</p>
        </div>
        <div className="text-center h-[15]">
          <img src={gapsImg} className="rounded h-full w-full" />
          <p className="text-slate-700 text-xl">{t("irmanager.step3")}</p>
        </div>
        <div className="text-center h-[15]">
          <img src={reportsImg} className="rounded h-full w-full" />
          <p className="text-slate-700 text-xl">{t("irmanager.step4")}</p>
        </div>
      </div>
      {/* SMALLER SCREEN */}
      <div className="hidden sm:block lg:hidden">
        <div className="grid grid-cols-2 p-8 gap-x-8">
          <div className="text-center h-[12]">
            <img src={ptsImg} className="rounded h-full w-full" />
            <p className="text-slate-700 text-xl">{t("irmanager.step1")}</p>
          </div>
          <div className="text-center h-[12]">
            <img src={rowsImg} className="rounded h-full w-full w-min-[100%]" />
            <p className="text-slate-700 text-xl">{t("irmanager.step2")}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 p-8 gap-x-8">
          <div className="text-center h-[12]">
            <img src={gapsImg} className="rounded h-full w-full" />
            <p className="text-slate-700 text-xl">{t("irmanager.step3")}</p>
          </div>
          <div className="text-center h-[12]">
            <img src={reportsImg} className="rounded h-full w-full" />
            <p className="text-slate-700 text-xl">{t("irmanager.step4")}</p>
          </div>
        </div>
      </div>
      {/* MOBILE SCREEN */}
      <div className="block sm:hidden p-8">
        <div className="text-center h-[12]">
          <img src={ptsImg} className="rounded h-full w-full" />
          <p className="text-slate-700 text-xl">{t("irmanager.step1")}</p>
        </div>
        <div className="text-center h-[12] mt-6">
          <img src={rowsImg} className="rounded h-full w-full w-min-[100%]" />
          <p className="text-slate-700 text-xl">{t("irmanager.step2")}</p>
        </div>
        <div className="text-center h-[12] mt-6">
          <img src={gapsImg} className="rounded h-full w-full" />
          <p className="text-slate-700 text-xl">{t("irmanager.step3")}</p>
        </div>
        <div className="text-center h-[12] mt-6">
          <img src={reportsImg} className="rounded h-full w-full" />
          <p className="text-slate-700 text-xl">{t("irmanager.step4")}</p>
        </div>
      </div>
    </>
  );
};
