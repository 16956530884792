import * as React from "react";

export const ContactIllustration = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={890}
    height={729.787}
    viewBox="0 0 890 729.787"
    data-name="Layer 1"
    {...props}
  >
    <path
      fill="#2f2e41"
      d="m219.677 665.91 1.81 38.156v.002a24.345 24.345 0 0 1-23.163 25.47l-.79.037-2.964-62.474Z"
    />
    <path
      fill="#e6e6e6"
      d="M762.171 601.427c-18.173 35.465-41.224 69.764-70.389 100.808-.648.7-1.302 1.384-1.962 2.08l-69.1-32.714c.422-.702.885-1.49 1.384-2.352 29.683-50.787 208.865-360.592 203.203-430.759.66 5.787 21.898 197.17-63.136 362.937Z"
    />
    <path
      fill="#e6e6e6"
      d="M705.113 708.546a92.323 92.323 0 0 1-2.957 1.608l-51.838-24.54c.725-.515 1.57-1.123 2.54-1.806 15.88-11.403 63.105-45.614 109.313-82.38 49.658-39.512 98.152-81.965 105.354-102.23-1.463 4.648-46.914 145.898-162.412 209.348Z"
    />
    <circle cx={86.23} cy={648.051} r={80} fill="#22c55e" />
    <path
      fill="#fff"
      d="M118.275 614.48h-64.09a10.694 10.694 0 0 0-10.682 10.681v45.78a10.694 10.694 0 0 0 10.681 10.681h64.091a10.694 10.694 0 0 0 10.682-10.682v-45.779a10.694 10.694 0 0 0-10.682-10.682Zm0 6.103a4.559 4.559 0 0 1 1.134.148l-33.172 26.461-32.978-26.515a4.58 4.58 0 0 1 .925-.094Zm0 54.935h-64.09a4.583 4.583 0 0 1-4.579-4.578v-45.368l34.711 27.91a3.052 3.052 0 0 0 3.816.006l34.72-27.696v45.148a4.583 4.583 0 0 1-4.578 4.578Z"
    />
    <path
      fill="#ffb8b8"
      d="m394.968 532.551 17.9.148a12.454 12.454 0 0 0 12.399-14.43 12.454 12.454 0 0 0-17.807-9.192l-16.88 8.33-57.737 12.875-24.735-25.289-14.208 23.401 33.378 25.788Z"
    />
    <path
      fill="#2f2e41"
      d="m391.613 717.49-21.09 4.056c-14.404-45.409-30.012-88.411-30.011-121.67-20.386 22.688-45.667 35.669-75.436 39.745l3.245-50.29 24.334-12.979 37.243-17.189a28.28 28.28 0 0 1 10.365-2.563 28.28 28.28 0 0 1 29.412 23.773Z"
    />
    <path
      fill="#2f2e41"
      d="m290.627 633.537-8.405 6.389a26.03 26.03 0 0 1-25.663 3.345c-24.075-18.046-41.179-12.722-56.681 14.516-18.594-20.985-23.182-51.294-6.587-82.651l92.064 2.028 18.656-5.678Z"
    />
    <path
      fill="#2f2e41"
      d="M259.967 727.517a23.507 23.507 0 0 1-27.82-11.721c-8.274-7.288-15.255-17.721-21.31-30.409a235.353 235.353 0 0 1-11.167-28.683c-4.19-12.94 2.816-27.077 15.855-30.948q.582-.173 1.177-.326c17.407-4.469 35.282 5.57 41.232 22.528l17.3 49.308a23.507 23.507 0 0 1-14.398 29.964q-.432.152-.87.287Z"
    />
    <path
      d="M362.891 764.878s20.279 22.712 24.334 35.69"
      opacity={0.2}
      style={{
        isolation: "isolate",
      }}
      transform="translate(-155 -85.106)"
    />
    <path
      fill="#ffb8b8"
      d="m129.244 572.587-6.787 16.564a12.454 12.454 0 0 0 8.791 16.872 12.454 12.454 0 0 0 15.15-13.118l-1.463-18.767 9.496-58.387 32.668-13.57-16.447-21.885-36.343 21.409Z"
    />
    <path
      fill="#22c55e"
      d="M291.438 575.136c-23.306 39.147-69.767 54.306-109.56 23.651 7-23 29.6-86.566 15-89-31 13-31.045-17.523-36.844-32.799l70.57-25.956 38.123 10.545 7.814 2.773a26.42 26.42 0 0 1 17.583 24.537c-16.015 23.135-27.87 47.815-2.686 86.249Z"
    />
    <path
      fill="#22c55e"
      d="m300.36 543.502-27.983-23.118 9.328-53.94L319.828 507Z"
    />
    <path
      fill="#3f3d56"
      d="M764.705 172.945h-4V63.4a63.4 63.4 0 0 0-63.4-63.4H465.218a63.4 63.4 0 0 0-63.4 63.4v600.974a63.4 63.4 0 0 0 63.4 63.4h232.085a63.4 63.4 0 0 0 63.4-63.4V250.921h4Z"
      data-name="b82a9922-ead1-40af-af3b-f133b244cde7"
    />
    <path
      fill="#fff"
      d="M699.862 16.493h-30.295a22.495 22.495 0 0 1-20.828 30.994H515.78a22.495 22.495 0 0 1-20.826-30.991h-28.3a47.348 47.348 0 0 0-47.348 47.348v600.089a47.348 47.348 0 0 0 47.348 47.348h233.2a47.348 47.348 0 0 0 47.348-47.348V63.84a47.348 47.348 0 0 0-47.348-47.348h.009Z"
      data-name="b2a7827b-2d2c-407d-93be-35e4c67116bc"
    />
    <circle
      cx={583.658}
      cy={651.746}
      r={26}
      fill="#e6e6e6"
      data-name="e6b07811-3d0f-4972-ba4c-46b0c6566e33"
    />
    <path
      fill="#e6e6e6"
      d="M478.182 411.298c-2.457 0-4.457 2.283-4.457 5.088s2 5.088 4.457 5.088h210.143c2.457 0 4.457-2.283 4.457-5.088s-2-5.088-4.457-5.088ZM478.182 531.298c-2.457 0-4.457 2.283-4.457 5.088s2 5.088 4.457 5.088h210.143c2.457 0 4.457-2.283 4.457-5.088s-2-5.088-4.457-5.088ZM478.182 441.826c-2.457 0-4.457 2.282-4.457 5.087s2 5.088 4.457 5.088h90.425c2.458 0 4.458-2.282 4.458-5.088s-2-5.087-4.458-5.087ZM478.182 470.924c-2.457 0-4.457 2.283-4.457 5.088s2 5.088 4.457 5.088h210.143c2.457 0 4.457-2.283 4.457-5.088s-2-5.088-4.457-5.088ZM478.182 501.451c-2.457 0-4.457 2.283-4.457 5.088s2 5.088 4.457 5.088h90.425c2.458 0 4.458-2.283 4.458-5.088s-2-5.088-4.458-5.088Z"
    />
    <path
      fill="#22c55e"
      d="M517.853 179.627h130.814a5.457 5.457 0 0 1 5.45 5.45v136.238a5.457 5.457 0 0 1-5.45 5.45H517.853a5.457 5.457 0 0 1-5.45-5.45V185.078a5.457 5.457 0 0 1 5.45-5.45Z"
    />
    <path
      fill="#fff"
      d="M620.793 298.67a3.7 3.7 0 1 0 .004-7.4h-75.07a3.7 3.7 0 1 0-.004 7.4h75.07ZM620.793 276.868a3.7 3.7 0 0 0 0-7.4h-75.066a3.7 3.7 0 1 0-.004 7.4h75.07ZM583.26 207.722a23.293 23.293 0 1 1-23.293 23.292 23.319 23.319 0 0 1 23.293-23.292Z"
    />
    <path
      fill="#2f2e41"
      d="M286.064 443.97h-69.15a5.371 5.371 0 0 1-5.365-5.365V408.8a39.94 39.94 0 1 1 79.88 0v29.806a5.371 5.371 0 0 1-5.365 5.365Z"
    />
    <circle
      cx={414.124}
      cy={497.999}
      r={29.283}
      fill="#ffb6b6"
      transform="rotate(-61.337 264.866 586.134)"
    />
    <path
      fill="#2f2e41"
      d="M300.878 411.78h-42.283l-.434-6.07-2.168 6.07h-6.51l-.86-12.032-4.297 12.032h-12.598v-.596a31.63 31.63 0 0 1 31.594-31.595h5.962a31.63 31.63 0 0 1 31.594 31.595Z"
    />
    <path
      fill="#2f2e41"
      d="M258.248 449.534a5.481 5.481 0 0 1-.949-.084l-30.962-5.463v-51.17h34.083l-.844.983c-11.74 13.693-2.895 35.894 3.422 47.91a5.286 5.286 0 0 1-.42 5.612 5.344 5.344 0 0 1-4.33 2.212ZM361.333 702.175l38.2-.001h.001a24.345 24.345 0 0 1 24.344 24.343v.791l-62.544.003Z"
    />
    <path fill="#cacaca" d="M889 729.787H1a1 1 0 0 1 0-2h888a1 1 0 0 1 0 2Z" />
  </svg>
);
