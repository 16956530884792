import { LogoIcon } from "../icons/LogoIcon";
import { HeaderLink } from "./HeaderLink";

export const Header = ({ t }) => {
  return (
    <div
      className="top-0 w-full h-[50px] bg-transparent text-left px-8 sm:px-16
    flex justify-between z-10 absolute"
    >
      <div className="flex items-center h-full">
        <LogoIcon height={32} width={26} className="mx-0 px-0" />
        <p className="text-slate-50 font-bold text-2xl ml-2">Pix</p>
        <p className="text-green-500 font-bold text-2xl">2</p>
        <p className="text-slate-50 font-bold text-2xl">Agro</p>
      </div>
      <div className=" items-center h-full hidden sm:flex">
        <HeaderLink href="#about-us">{t("header.link1")}</HeaderLink>
        <HeaderLink href="#solutions" className="ml-4">
          {t("header.link2")}
        </HeaderLink>
        <HeaderLink href="#contact" className="ml-4">
          {t("header.link3")}
        </HeaderLink>
      </div>
    </div>
  );
};
